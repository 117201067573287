import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["selected", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("label", {
      for: "v-switch",
      style: {"font-size":"larger","font-family":"Roboto, sans-serif"}
    }, "Version: ", -1)),
    _createElementVNode("select", {
      id: "version-switch",
      name: "v-switch",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      style: {"font-size":"large","font-family":"sans-serif","font-weight":"700","color":"#444","line-height":"1.3","padding":"0.6em 1.4em 0.5em 0.8em","max-width":"100%","box-sizing":"border-box","margin":"0","border":"1px solid #aaa","box-shadow":"0 1px 0 1px rgba(0, 0, 0, 0.04)","border-radius":"0.5em","appearance":"none","-moz-appearance":"none","-webkit-appearance":"none","background-color":"#fff"}
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versions, (version) => {
        return (_openBlock(), _createElementBlock("option", {
          key: version,
          selected: _ctx.currentVersion === version,
          value: version
        }, _toDisplayString(version), 9, _hoisted_1))
      }), 128))
    ], 32)
  ], 64))
}