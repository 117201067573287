import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { slot: "overview" }
const _hoisted_2 = { slot: "header" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overview = _resolveComponent("Overview")!
  const _component_VersionSwitch = _resolveComponent("VersionSwitch")!
  const _component_rapi_doc = _resolveComponent("rapi-doc")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_rapi_doc, {
      id: "api-docs",
      theme: "light",
      "render-style": "focused",
      "allow-spec-url-load": "false",
      "allow-spec-file-load": "false",
      "allow-server-selection": "false",
      "server-url": _ctx.serverUrl,
      "fill-request-fields-with-example": "false",
      "allow-authentication": "true",
      "allow-try": "true",
      "spec-url": _ctx.getSpecUrl,
      "allow-spec-file-download": "true",
      "persist-auth": "true",
      "show-method-in-nav-bar": "as-colored-text",
      "use-path-in-nav-bar": "true",
      "primary-color": "#461d9e",
      "nav-bg-color": "#fff",
      "nav-hover-bg-color": "#eceffd",
      "nav-hover-text-color": "#185eeb",
      "header-color": "#fff",
      "regular-font": "sans-serif",
      "mono-font": "Roboto",
      "nav-item-spacing": "relaxed",
      "allow-search": "true",
      "allow-advanced-search": "true",
      "default-schema-tab": "schema",
      "schema-description-expanded": "true"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Overview)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          style: {"margin":"1em 2em"},
          slot: "logo",
          src: "snyk-api.svg"
        }, null, -1)),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.versions.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Loading..."))
            : (_openBlock(), _createBlock(_component_VersionSwitch, {
                key: 1,
                "current-version": _ctx.currentVersion,
                versions: _ctx.versions,
                onChange: _ctx.changeVersion
              }, null, 8, ["current-version", "versions", "onChange"]))
        ])
      ]),
      _: 1
    }, 8, ["server-url", "spec-url"])
  ]))
}