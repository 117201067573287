// This file exists because it helps mock side effects in tests.
// Please put here only functions with side effects.

interface SpecLoader {
  loadSpec(url: string): void;
}

export const BASE_SPEC_URL = `${process.env.VUE_APP_ROOT_API}/rest/openapi`;
export const LOCATION_WATCH_RATE = 1000;

export const getCurrentUrl = (): string => window.location.toString();

export const watchUrlChange = (
  callback: (url: string) => void,
): ReturnType<typeof setInterval> => {
  let oldUrl = getCurrentUrl();

  return setInterval(() => {
    const newUrl = getCurrentUrl();

    if (oldUrl != newUrl) {
      callback(newUrl);
      oldUrl = newUrl;
    }
  }, LOCATION_WATCH_RATE);
};

export const loadSpec = (version: string): void => {
  const rd = document.getElementById('api-docs') as unknown as SpecLoader;
  rd.loadSpec(`${BASE_SPEC_URL}/${version}`);
};

export const loadVersions = async (): Promise<string[]> => {
  const response = await fetch(BASE_SPEC_URL);
  return response.json();
};

export const setCurrentVersion = (version: string): void => {
  const url = new URL(getCurrentUrl());
  url.searchParams.set('version', version);
  window.history.pushState({}, '', url.toString());
};

export const getAPIServerURL = (): string => {
  return `${process.env.VUE_APP_ROOT_API}`;
};
